import { BrowserRouter, Routes, Route } from "react-router-dom";

// import AdminAuthCheck from "../../components/AuthCheck/AdminAuthCheck";
import Homepage from "../../views/homepage";
import ClientLayout from "../../components/ClientLayout";
import CreateAccount from "../../views/createAccount";
import Login from "../../views/login";
import ForgetPassword1 from "../../views/forget-password-1";
import ForgetPassword2 from "../../views/forget-password-2";
import ForgetPassword3 from "../../views/forget-password-3";
import AboutUs from "../../views/aboutUs";
import PrivacyPolicy from "../../views/privacyPolicy";
import TermsConditions from "../../views/termsConditions";
import ProtectedRoute from "../../views/protectedRoute";
import Profile from "../../views/profile";
import EditProfile from "../../views/profile/editProfile";
import ChangePassword from "../../views/profile/changePassword";
import Help from "../../views/help";
import Dashboard from "../../views/dashboard";
import Notifications from "../../views/notifications/";
import ManageProduct from "../../views/manageProduct";
import ManageProductDetails from "../../views/manageProduct/manageProductDetails";
import AddProduct from "../../views/manageProduct/addProduct";
import EditProduct from "../../views/manageProduct/editProduct";
// import Notifications from "../../views/notifications";

const MyRouter = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route
          path="/"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
              bg={false}
              footer={false}
            >
              <Homepage />
            </ClientLayout>
          }
        />
        <Route
          path="/createAccount"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <CreateAccount />
            </ClientLayout>
          }
        />
        <Route
          path="/login"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Login />
            </ClientLayout>
          }
        />
        <Route
          path="/forget-password-1"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ForgetPassword1 />
            </ClientLayout>
          }
        />
        <Route
          path="/forget-password-2"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ForgetPassword2 />
            </ClientLayout>
          }
        />
        <Route
          path="/forget-password-3"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ForgetPassword3 />
            </ClientLayout>
          }
        />
        <Route
          path="/aboutUs"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <AboutUs />
            </ClientLayout>
          }
        />
        <Route
          path="/privacyPolicy"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <PrivacyPolicy />
            </ClientLayout>
          }
        />
        <Route
          path="/termsConditions"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <TermsConditions />
            </ClientLayout>
          }
        />
        <Route
          path="/profile"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Profile />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/editProfile"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <EditProfile />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/changePassword"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <ChangePassword />
              </ClientLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/help"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Help />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Dashboard />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/notifications"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Notifications />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/manageProduct"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <ManageProduct />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/addProduct"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <AddProduct />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/editProduct"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <EditProduct />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/manageProduct/:id"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <ManageProductDetails />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default MyRouter;
