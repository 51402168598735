import { useEffect, useRef, useState } from "react";
import { Col, Row, Card, Spin, Divider, Image, Button } from "antd";
import { useNavigate, useParams } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import { marketplaceData } from "../../components/Data/data";
import phoneimg from "../../assets/phone-img.png";
import emailimg from "../../assets/email-img.png";
import Slider from "react-slick";
import { Get } from "../../config/api/get";
import { useSelector } from "react-redux";
import { PRODUCT, UPLOADS_URL } from "../../config/constants/api";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { Delete } from "../../config/api/delete";
import swal from "sweetalert";

const MarketPlaceDetail = () => {
  const token = useSelector((state) => state.user.userToken);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [allImages, setAllImages] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, [[slider1?.current, slider2?.current, allImages]]);
  const navigate = useNavigate();
  const [productDetails, setProductDetails] = useState(null);
  const { id } = useParams();
  const [marketprod, setMarketprod] = useState(
    marketplaceData.find((item) => item.id == id)
  );
  const handleDelete = (id) => {
    Delete(`${PRODUCT.deleteProduct}${id}`, token)
      .then((response) => {
        if (response?.status) {
          swal("System Alert!", response?.message, "success");
          navigate("/manageProduct");
        }
      })
      .catch((err) => {
        console.log(err);
        const message = err?.response?.data?.message;
        if (message) {
          swal("Oops!", message, "error");
        }
      });
  };
  const getProductDetails = () => {
    Get(`${PRODUCT.getProduct}${id}`, token)
      .then((response) => {
        setProductDetails(response?.data);
        setAllImages([response?.data?.image, ...response?.data?.gallery]);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error Fetching Product Details", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getProductDetails();
  }, []);

  const sliderSettings = {
    arrows: false,
  };
  const sliderSettings2 = {};

  return (
    <div className="shop-page">
      {!loading && (
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col xs={23} md={21}>
            <Row style={{ width: "100%", justifyContent: "center" }}>
              <Col lg={24}>
                <div class="arrow-box">
                  <FaArrowLeftLong
                    className="arrow"
                    onClick={() => navigate(-1)}
                  />
                  <h3 className="main-heading">Product Details</h3>
                </div>
              </Col>
            </Row>
            <Row justify="center" gutter={[16, 16]}>
              <Col lg={17}>
                <Card className="details-card">
                  <Row>
                    <Col lg={24}>
                      <div className="delete-and-edit">
                        <Button
                          type="danger"
                          className="edit-btn"
                          style={{ padding: "5px" }}
                          onClick={() =>
                            navigate("/editProduct", {state : {productDetails}})
                          }
                        >
                          <FaEdit />
                        </Button>
                        <Button
                          type="danger"
                          className="delete-btn"
                          onClick={() => handleDelete(productDetails._id)}
                        >
                          <RiDeleteBin6Line />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row align="" gutter={[16, 16]}>
                    <Col lg={10}>
                      <div
                        className="one-product"
                        style={{ paddingRight: "30px" }}
                      >
                        <div className="search-img">
                          <div>
                            <Slider
                              asNavFor={nav2}
                              ref={slider1}
                              {...sliderSettings}
                            >
                              {Array.isArray(allImages) &&
                                allImages.map((image, index) => {
                                  return (
                                    <div className="gray-bg" key={index}>
                                      <span>
                                        {" "}
                                        <img
                                          src={UPLOADS_URL + image}
                                          alt=""
                                          className="img-fluid"
                                          style={{ width: "100%" }}
                                        />
                                      </span>
                                    </div>
                                  );
                                })}
                            </Slider>
                            <Slider
                              asNavFor={nav1}
                              ref={slider2}
                              slidesToShow={productDetails?.gallery?.length}
                              swipeToSlide={true}
                              focusOnSelect={true}
                              {...sliderSettings2}
                            >
                              {Array.isArray(allImages) &&
                                allImages.map((image, index) => {
                                  return (
                                    <div key={index} className="slider-nav">
                                      <span>
                                        <img
                                          src={UPLOADS_URL + image}
                                          alt=""
                                          className="img-fluid"
                                          style={{
                                            width: "100%",
                                            height: "90px",
                                            objectFit: "cover",
                                            objectPosition: "center",
                                            maxWidth: "150px",
                                          }}
                                        />
                                      </span>
                                    </div>
                                  );
                                })}
                            </Slider>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={14}>
                      <div className="room-details">
                        <div>
                          <h4 className="text-26">{productDetails?.title}</h4>
                        </div>
                        <div className="search-result-detail-btnbox">
                          <h6 className="per-day-price">
                            ${productDetails?.price + ".00"}
                          </h6>
                        </div>
                      </div>
                      <Divider dashed />
                      <div className="search-img-box-right additional-details">
                        <h5 className="heading-18">Description</h5>
                        <p className="web-p">{productDetails?.description}</p>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col lg={7}>
                <Card className="details-card">
                  <h4 className="text-26">Seller Details</h4>
                  <div className="">
                    <div className="jump-inner-detail" style={{ gap: "10px" }}>
                      <span style={{ maxWidth: "60px", maxHeight: "60px" }}>
                        <img
                          src={UPLOADS_URL + productDetails?.vendor?.image}
                          alt=""
                          className="img-fluid"
                          style={{ width: "100%" }}
                        />
                      </span>
                      <h4 className="text-16">
                        {productDetails?.vendor?.firstName +
                          " " +
                          productDetails?.vendor?.lastName}
                      </h4>
                    </div>
                    <div className="jump-inner-detail" style={{ gap: "10px" }}>
                      <Image
                        preview={false}
                        alt={"Failed to load image"}
                        src={phoneimg}
                        className=""
                      />
                      <h4 className="text-16">
                        {productDetails?.vendor?.mobile}
                      </h4>
                    </div>
                    <div className="jump-inner-detail" style={{ gap: "10px" }}>
                      <Image
                        preview={false}
                        alt={"Failed to load image"}
                        src={emailimg}
                        className=""
                      />
                      <h4 className="text-16">
                        {productDetails?.vendor?.email}
                      </h4>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {loading && <Spin style={{ margin: "10vw 50vw" }} />}
    </div>
  );
};

export default MarketPlaceDetail;
