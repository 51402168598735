import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Upload, Button } from "antd";
import DashbordSidebar from "../../components/DashboardSidebar";
import { PlusOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Put } from "../../config/api/put";
import { PRODUCT, UPLOADS_URL } from "../../config/constants/api";
import { useSelector } from "react-redux";
import swal from "sweetalert";

const { TextArea } = Input;
dayjs.extend(customParseFormat);

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};
const normFile = (e) => {
  if (Array.isArray(e)) {
    console.log(e);
    return e;
  }
  return e?.fileList;
};
const DropzoneFiltercards = () => {
  const token = useSelector((state) => state.user.userToken);
  const location = useLocation();
  const productDetails = location?.state?.productDetails;
  const [initialProductDetails, setInitialProductDetails] = useState(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [oldImages, setOldImages] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [newImages, setNewImages] = useState([]);

  useEffect(() => {
    if (!productDetails) {
      navigate(`/productManagement/${productDetails?._id}`);
    }
    setInitialProductDetails(productDetails);
    form.setFieldsValue({
      title: productDetails?.title,
      price: productDetails?.price,
      description: productDetails?.description,
    });
    const combinedImages = [
      ...(productDetails?.image ? [UPLOADS_URL + productDetails.image] : []),
      ...(productDetails?.gallery || []).map(
        (filename) => UPLOADS_URL + filename
      ),
    ];

    setFileList(
      combinedImages.map((url) => ({ uid: url, name: url, url: url }))
    );
    setExistingImages(
      combinedImages.map((url) => ({ uid: url, name: url, url: url }))
    );
  }, []);

  const onFinish = (values) => {
    const formValuesChanged = () => {
      if (!initialProductDetails) {
        return true;
      }
      return (
        values.description !== initialProductDetails?.description ||
        values.title !== initialProductDetails?.title ||
        values.price !== initialProductDetails?.price ||
        oldImages.length > 0 ||
        newImages.length > 0
      );
    };
    if (formValuesChanged()) {
      let data = new FormData();
      if (newImages.length > 0) {
        const galleryToAppend = newImages.map((file, index) => {
          return file.originFileObj;
        });
        galleryToAppend.forEach((val) => {
          data.append("gallery", val);
        });
      }
      if (oldImages.length > 0) {
        const oldImagesToAppend = oldImages.map((file, index) => {
          const parts = file.url.split("/");
          const filename = parts[parts.length - 1];
          return filename;
        });
        oldImagesToAppend.forEach((val, index) => {
          data.append(`oldImages[${index}]`, val);
        });
      }
      if (fileList[0]?.originFileObj) {
        data.append("image", fileList[0]?.originFileObj);
      }
      if (productDetails?.title !== values?.title) {
        data.append("title", values.title);
      }
      if (productDetails?.description !== values?.description) {
        data.append("description", values.description);
      }
      if (productDetails?.price !== values?.price) {
        data.append("price", values.price);
      }
      Put(
        `${PRODUCT.editProduct}${productDetails?._id}`,
        token,
        data,
        null,
        "multipart"
      )
        .then((response) => {
          if (response?.status) {
            swal("System Alert", "Product Updated Successfully", "success");
            navigate(`/manageProduct/${productDetails?._id}`);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          const message = err?.response?.data?.message;
          if (message) {
            swal("Oops", message, "error");
          }
        });
    } else {
      swal("System Alert!", "No changes detected", "error");
      return;
    }
  };

  const multipleChange = ({ fileList: newFileList }) => {
    const existingImageUIDs = existingImages.map((image) => image.uid);
    const newImageUIDs = newFileList.map((file) => file.uid);
    const removedImages = existingImages.filter(
      (image) => !newImageUIDs.includes(image.uid)
    );
    setOldImages([...removedImages]);
    const updatedFileList = newFileList.filter(
      (file) => !existingImageUIDs.includes(file.uid)
    );
    setNewImages(updatedFileList);
    setFileList(newFileList);
  };

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="bg-parent">
                        <Row align={"middle"} style={{ marginBottom: "15px" }}>
                          <Col lg={24}>
                            <div className="arrow-box2">
                              <div>
                                <FaArrowLeftLong
                                  className="arrow"
                                  onClick={() => navigate(-1)}
                                />
                              </div>
                              <h3 className="heading-28">Edit Product</h3>
                            </div>
                          </Col>
                        </Row>

                        <Row align="middle" gutter={24}>
                          <Col lg={12} md={24} xs={24}>
                            <Form
                              className="row g-3"
                              name="basic"
                              layout="vertical"
                              initialValues={{
                                remember: true,
                              }}
                              onFinish={onFinish}
                              autoComplete="off"
                              onFinishFailed={onFinishFailed}
                              form={form}
                            >
                              <Form.Item
                                label="Title Of Product"
                                name="title"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Title!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Title of Product"
                                  className="web-input"
                                  defaultValue="Dark Skydiving Goggles"
                                />
                              </Form.Item>

                              <p style={{ margin: "10px", fontWeight: "bold" }}>
                                Upload Gallery Images*
                              </p>
                              <Form.Item
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                              >
                                <Upload
                                  action="/upload.do"
                                  listType="picture-card"
                                  onChange={multipleChange}
                                  fileList={fileList}
                                >
                                  <button
                                    style={{
                                      border: 0,
                                      background: "none",
                                    }}
                                    type="button"
                                  >
                                    <PlusOutlined />
                                    <div
                                      style={{
                                        marginTop: 8,
                                      }}
                                    >
                                      Upload Gallery Images
                                    </div>
                                  </button>
                                </Upload>
                                <p className="web-p">
                                  (Recommended PNG, JPG upto 5MB)
                                </p>
                              </Form.Item>

                              <Form.Item
                                label="Price Of Product"
                                name="price"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Title!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Price of Product"
                                  className="web-input"
                                  defaultValue="$100"
                                />
                              </Form.Item>
                              <Form.Item
                                label="Description"
                                name="description"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Description!",
                                  },
                                ]}
                              >
                                <TextArea
                                  rows={4}
                                  placeholder="Enter Description"
                                  className="web-input"
                                  defaultValue="Enter Description"
                                />
                              </Form.Item>
                              <div
                                className="frame-group"
                                style={{ alignItems: "baseline" }}
                              >
                                <div className="">
                                  <Button
                                    type=""
                                    htmlType="submit"
                                    block
                                    size={"large"}
                                    style={{ marginBottom: "10px" }}
                                    className="web-btn"
                                  >
                                    Update
                                  </Button>
                                </div>
                                <div className="">
                                  <Button
                                    type=""
                                    block
                                    size={"large"}
                                    style={{
                                      marginBottom: "10px",
                                      background: "#b91717",
                                      color: "#fff",
                                    }}
                                    className="web-btn hover-white"
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </div>
                            </Form>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DropzoneFiltercards;
