import { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Input,
  Form,
  Select,
  Upload,
  message
} from "antd";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import DashbordSidebar from "../../../components/DashboardSidebar";
import "react-phone-number-input/style.css";
import swal from "sweetalert";
import { COMPANY, UPLOADS_URL, VENDOR } from "../../../config/constants/api";
import { Put } from "../../../config/api/put";
import { FaCamera } from "react-icons/fa";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const normFile = (e) => {
  if (Array.isArray(e)) {
    console.log(e);
    return e;
  }
  return e?.fileList;
};
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isImage = file.type.startsWith("image/");
  if (!isImage) {
    message.error("Invalid Uplaod, You can only upload image files!");
  }
  return isImage;
};
const DropzoneFiltercards = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const [initialData, setInitialData] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [imageObject, setImageObject] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.user.userToken);
  const profileDetails = useSelector((state) => state.user.profileDetails);
  const accountDetails = location?.state?.accountDetails;
  
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload Image (Recommended PNG, JPG upto 2MB)
      </div>
    </button>
  );  
  useEffect(() => {
    if (accountDetails) {
      setInitialData({
        firstName: accountDetails?.firstName,
        lastName: accountDetails?.lastName,
        gender: accountDetails?.gender,
        phone: accountDetails?.mobile,
      });
      form.setFieldsValue({
        firstName: accountDetails?.firstName,
        lastName: accountDetails?.lastName,
        email: accountDetails?.email,
        gender: accountDetails?.gender,
        phone: accountDetails?.mobile,
      });
      setImageUrl(UPLOADS_URL + accountDetails?.image)
    }
  }, []);

 
  const navigate = useNavigate();

  const onFinish = (values) => {
    const { firstName, lastName, gender, phone } = values;
    if (
      firstName === initialData?.firstName &&
      lastName === initialData?.lastName &&
      gender === initialData?.gender &&
      phone === initialData?.phone &&
      !imageObject
    ) {
      swal("System Alert!", "No changes detected", "error");
      return;
    } else {
      let data = new FormData();
      if (firstName) {
        data.append("firstName", firstName);
      }
      if (lastName) {
        data.append("lastName", lastName);
      }
      if (phone) {
        data.append("mobile", phone);
      }
      if (gender) {
        data.append("gender", gender);
      }
      if(imageObject){
        data.append('image', imageObject)
      }
      Put(VENDOR.updateMyProfile, token, data, null , 'multipart')
        .then((response) => {
          if (response.status) {
            swal("System Alert", "Account Updated Successfully", "success");
            navigate("/Profile");
          }
        })
        .catch((err) => {
          console.log(err)
          const message = err?.response?.data?.message;
          if (message) {
            swal("Oops!", message, 'error');
          }
        });
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleChangepro = (info) => {
    setLoading(true);
    getBase64(
      info?.fileList[info?.fileList?.length - 1]?.originFileObj,
      (url) => {
          setImageObject(info?.fileList[info?.fileList?.length - 1]?.originFileObj);
          setImageUrl(url);
        setLoading(false);
      }
    );
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="profile-information-wrapper">
                        <h3 className="main-heading">Edit Profile</h3>
                      </div>
                      <div className="bg-parent">
                        <Row
                          gutter={[16, 16]}
                          align={""}
                          justify={"space-between"}
                        >
                          <Col md={10} lg={10} xl={8}>
                          <Form.Item
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                              >
                                <>
                                  <Upload
                                    name="image"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeUpload}
                                    onChange={(e) => {
                                      handleChangepro(e)
                                    }}
                                  >
                                    {imageUrl ? (
                                      <img
                                        src={imageUrl}
                                        alt="avatar"
                                        style={{
                                          maxWidth: "100%",
                                          maxHeight: "280px",
                                          filter: "blur(1px)",
                                        }}
                                      />
                                    ) : (
                                      uploadButton
                                    )}
                                    <FaCamera
                                      style={{
                                        position: "absolute",
                                        color: "rgb(0,127,202)",
                                        fontSize: "25px",
                                      }}
                                    />
                                  </Upload>
                                </>
                              </Form.Item>
                          </Col>

                          <Col md={14} lg={14} xl={16}>
                            <div className="">
                              <div className="logo-rectangle">
                                <div className="edit-profile-info">
                                  <Form
                                    className="row g-3"
                                    name="basic"
                                    layout="vertical"
                                    initialValues={{
                                      remember: true,
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    form={form}
                                  >
                                    <Row
                                      style={{ width: "100%" }}
                                      gutter={[16, 16]}
                                    >
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="First Name"
                                          name="firstName"
                                          
                                        >
                                          <Input
                                            size="large"
                                            className="web-input"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Last Name"
                                          name="lastName"
                                          
                                        >
                                          <Input
                                            size="large"
                                            className="web-input"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Email"
                                          name="email"
                                        >
                                          <Input
                                            size="large"
                                            className="web-input"
                                            disabled
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Phone Number"
                                          name="phone"
                                        >
                                          <Input
                                            size="large"
                                            className="web-input"
                                            type="number"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Gender"
                                          name="gender"
                                        >
                                          <Select placeholder="Select">
                                            <Select.Option value="MALE">
                                              Male
                                            </Select.Option>
                                            <Select.Option value="FEMALE">
                                              Female
                                            </Select.Option>
                                            <Select.Option value="OTHERS">
                                              Others
                                            </Select.Option>
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col lg={24} md={24} xs={24}> 
                                      <div
                                        className=""
                                      >
                                        <Button
                                          type=""
                                          htmlType="submit"
                                          className="btn web-btn px-5"
                                        >
                                          Update
                                        </Button>
                                      </div>
                                      </Col>
                                      
                                    </Row>
                                  </Form>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DropzoneFiltercards;
