import React, { useState } from "react";
import { Col, Row, Form, Input, Upload, Button, message } from "antd";
import DashbordSidebar from "../../components/DashboardSidebar";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Post } from "../../config/api/post";
import { PRODUCT } from "../../config/constants/api";
import { useSelector } from "react-redux";
import swal from "sweetalert";

const { TextArea } = Input;
dayjs.extend(customParseFormat);
const onChangetime = (time, timeString) => {
  console.log(time, timeString);
};

const onChange = (date, dateString) => {
  console.log(date, dateString);
};

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};
const normFile = (e) => {
  if (Array.isArray(e)) {
    console.log(e);
    return e;
  }
  return e?.fileList;
};
const DropzoneFiltercards = () => {
  const token = useSelector((state) => state.user.userToken);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [gallery, setGallery] = useState([]);
  const multipleChange = (e) => {
    setGallery(e.fileList);
  };
  const onFinish = (values) => {
    const { title, description, price } = values;
    let data = new FormData();
    data.append("title", title);
    data.append("description", description);
    data.append("price", price);
    if (gallery.length > 1) {
      const galleryToAppend = gallery.slice(1).map((file) => {
        return file.originFileObj;
      });
      galleryToAppend.forEach((val) => {
        data.append("gallery", val);
      });
    }
    data.append("image", gallery[0]?.originFileObj);
    Post(PRODUCT.addProduct, data, token, null , 'multipart')
      .then((response) => {
        if (response?.status) {
          form.resetFields();
          swal("System Alert!", response?.message, "success");
          navigate("/manageProduct");
        }
      })
      .catch((err) => {
        console.log(err);
        const message = err?.response?.data?.message;
        if (message) {
          swal("Oops", message, "error");
        }
      });
  };



  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("Invalid Uplaod, You can only upload image files!");
    }
    return isImage;
  };    
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="bg-parent">
                        <Row align={"middle"} style={{ marginBottom: "15px" }}>
                          <Col lg={24}>
                            <div className="arrow-box2">
                              <div>
                                <FaArrowLeftLong
                                  className="arrow"
                                  onClick={() => navigate(-1)}
                                />
                              </div>
                              <h3 className="heading-28">Add Product</h3>
                            </div>
                          </Col>
                        </Row>

                        <Row align="middle" gutter={24}>
                          <Col lg={12} md={24} xs={24}>
                            <Form
                              className="row g-3"
                              name="basic"
                              layout="vertical"
                              initialValues={{
                                remember: true,
                              }}
                              onFinish={onFinish}
                              autoComplete="off"
                              onFinishFailed={onFinishFailed}
                              form={form}
                            >
                              <Form.Item
                                label="Title Of Product"
                                name="title"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Title!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Title of Product"
                                  className="web-input"
                                />
                              </Form.Item>

                              <p style={{ margin: "10px", fontWeight: "bold" }}>
                                Upload Images*
                              </p>
                              <Form.Item
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                              >
                                 <Form.Item
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                              >
                                <Upload
                                  action="/upload.do"
                                  listType="picture-card"
                                  onChange={multipleChange}
                                >
                                  <button
                                    style={{
                                      border: 0,
                                      background: "none",
                                    }}
                                    type="button"
                                  >
                                    <PlusOutlined />
                                    <div
                                      style={{
                                        marginTop: 8,
                                      }}
                                    >
                                      Upload Gallery Images
                                    </div>
                                  </button>
                                </Upload>
                              </Form.Item>
                                <p className="web-p">(Recommended PNG, JPG upto 5MB)</p>
                              </Form.Item>

                              <Form.Item
                                label="Price Of Product"
                                name="price"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Title!",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Price of Product"
                                  className="web-input"
                                />
                              </Form.Item>
                              <Form.Item
                                label="Description"
                                name="description"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Description!",
                                  },
                                ]}
                              >
                                <TextArea
                                  rows={4}
                                  placeholder="Enter Description"
                                  className="web-input"
                                />
                              </Form.Item>
                              <div
                                className="frame-group"
                                style={{ alignItems: "baseline" }}
                              >
                                <div className="">
                                  <Button
                                    type=""
                                    htmlType="submit"
                                    block
                                    size={"large"}
                                    style={{ marginBottom: "10px" }}
                                    className={
                                      !gallery.length > 0
                                        ? "web-btn3"
                                        : "web-btn"
                                    }
                                    disabled={
                                      !gallery.length > 0
                                    }
                                  >
                                    Publish
                                  </Button>
                                </div>
                                <div className="">
                                  <Button
                                    type=""
                                    block
                                    size={"large"}
                                    style={{
                                      marginBottom: "10px",
                                      background: "#b91717",
                                      color: "#fff",
                                    }}
                                    className="web-btn hover-white"
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </div>
                            </Form>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DropzoneFiltercards;

